import Newsslider from "./Newsslider";

const Newsinhome = () => {

  return (
    <div>
      <div className="make-it-center">
        <div className="line-titleofpart2"></div>
        <p className="titleofpart2">اخبار و رویداد های مهم</p>
        <div className="line-titleofpart2"></div>
      </div>
      <div className="make-it-center">
        <Newsslider />
      </div>

      <div className="make-it-center1 big-news-version" style={{marginTop:"40px"}}>
        <div className="one-little-news one-little-news-image1">
          <p className="one-little-news-title">
            ریزش 30 تا 90 میلیونی قیمت خودرو های مونتاژی در بازار
          </p>
          <div className="make-it-center"><p className="one-little-news-p">وبلاگ leave a comment</p></div>
          <div className="make-it-center">
            <p className="one-little-news-p">
            این محتوا قفل و محدود شده است لطفا عضو شوید
            </p>
          </div>
          <div className="make-it-center">
            <button className="one-little-news-see-more">ادامه مطلب →</button>
          </div>
        </div>

        <div className="one-little-news one-little-news-image2">
          <p className="one-little-news-title">
            نگاهی به وضعیت شاخص کل سال 1402
          </p>
          <div className="make-it-center"><p className="one-little-news-p">وبلاگ leave a comment</p></div>
          <div className="make-it-center">
            <p className="one-little-news-p">
            این محتوا قفل و محدود شده است لطفا عضو شوید
            </p>
          </div>
          <div className="make-it-center">
            <button className="one-little-news-see-more">ادامه مطلب →</button>
          </div>
        </div>

        <div className="one-little-news one-little-news-image3">
          <p className="one-little-news-title">قطعیت در بازار های مالی</p>
          <div className="make-it-center">
            <p className="one-little-news-p">وبلاگ1 comment</p>
          </div>
          <div className="make-it-center">
            <p className="one-little-news-p">
              یه مشکلی که اغلب توی بازارهای مالی دارند اینکه دنبال قطعیت بالا
              هستند و بازار اونا را ناامید می کنه …… البته
            </p>
          </div>
          <div className="make-it-center">
            <button className="one-little-news-see-more">ادامه مطلب →</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Newsinhome;
