export const Newsfooter = [
  {
    news: "ریزش 30 تا 90 میلیونی قیمت خودرو های مونتاژی در بازار",
    date: "۲ خرداد ۱۴۰۲",
  },
  {
    news: "نگاهی به وضعیت شاخص کل سال 1402",
    date: "۲ خرداد ۱۴۰۲",
  },
  {
    news: "قطعیت در بازار های مالی",
    date: "۲ خرداد ۱۴۰۲",
  },
];
