import Fouroptions from "../Fouroption";

const News = () => {

  return (
    <div>
      <div className="news-section">
        <div className="market-news">
          <img className="news-image"></img>
          <p className="news-title">نگاهی به وضعیت شاخص کل سال 1402</p>
          <p className="address-of-weblog">اخبار بازار2 Comments</p>
          <p className="this-lock">این محتوا قفل و محدود شده است لطفا عضو شوید</p>
          <button className="one-little-news-see-more1">ادامه مطلب →</button>
        </div>

        <div className="fouroption">
          {Fouroptions.map((oneoption, key)=>
          <div className="option">
            <div>{oneoption.icon}</div>
            <h4>{oneoption.word}</h4>
          </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default News;
