import Fouroptions from "../Fouroption";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {yupResolver}  from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

const Call = () => {

    const Schema = yup.object().shape({
        email : yup.string().email().required(),
        name : yup.string() ,
        telephone: yup.number().required("Telephone is Required").min(12).max(11),
        company: yup.string().required(),
        message: yup.string().required(),
        checkbox: yup.boolean().oneOf([true],'Please Check the Box')
    })

    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver : yupResolver(Schema)
    })

    const Submit = (data : any) => {
        console.log(data)
    }

  return (
    <div>
        <div className="call-page">

            <div className='call-info' >
                <h3 className="call-title-page">اطلاعات تماس</h3>
                <p className="info-call-page">دفتر بورس شارپ ●</p>
                <p className="info-call-page">آی دی ادمین پشتیبان Burssharp backup@ ●</p>
                <div className="three-links-call">
                    <a className="call-link-part">info@bourssharpedu.com</a>
                    <a className="call-link-part">vip@bourssharpedu.com</a>
                    <a className="call-link-part">bourssharp@gmail.com</a>
                </div>
            </div>

            <div className='call-form'>
                <h3 className="form-title">برای ما پیام ارسال کنید</h3>
                <p className="form-p">جهت ارتباط با ما و رسیدگی به سوالات و درخواست های شما از طریق فرم زیر می توانید اقدام بفرمایید</p>
                <form onSubmit={handleSubmit(Submit)}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div>
                            <input className="two-up-form" type="email" placeholder="E-mail" {...register("email")}></input>
                            <p className="form-error">{errors.email?.message}</p>
                        </div>
                        <div>
                            <input className="two-up-form" type="name" placeholder="Name" {...register("name")}></input>
                        </div>
                    </div>
                    <input className="form-input" type="telephone" placeholder="Telephone" {...register("telephone")}></input>
                    <p className="form-error">{errors.telephone?.message}</p>
                    <input className="form-input" type="company" placeholder="Company" {...register("company")}></input>
                    <p className="form-error">{errors.company?.message}</p>
                    <input className="form-input message-input" type="message" placeholder="Message" {...register("message")}></input>
                    <p className="form-error">{errors.message?.message}</p>

                    <p className="check-box">.با استفاده از این فرم شما با ذخیره و مدیریت اطلاعات خود با این وب سایت موافقت می کنید<span><input style={{cursor:"pointer"}} type="checkbox" {...register("checkbox")}></input></span></p>
                    
                    <p className="form-error">{errors.checkbox?.message}</p>
                    <input className="submit" type="submit" value={"submit"}></input>
                </form>
            </div>

            <div className="fouroption">
            {Fouroptions.map((oneoption, key)=>
            <div style={{margin:"28px 0px 28px 0px"}}>
              <Link to={oneoption.to}  className="option">
                {oneoption.icon}
                <h4>{oneoption.word}</h4>
              </Link>
            </div>
            )}
            </div>

        </div>
    </div>
  )
}

export default Call