import Shape from "./Shape";
import { Shape2 } from "./Shape";
import { Link } from "react-router-dom";
import Fouroptions from "../Fouroption";


const Homefirst = () => {

  const width :number = 30
  
  return (
    <div>
      <section className="first-part-home">
        <div className="information">
          <div className="bigtexts">
            <p className="firstbigtext">سرمایه گذاری مطمئن</p>
            <p className="secondbigtext">بورس + بازار های مالی</p>
            <button className="homefirstbutton">با عضویت در اشتراک</button>
          </div>

          <div className="fouroption">

            {Fouroptions.map((oneoption, key)=>
            <div style={{margin:"28px 0px 28px 0px"}}>
              <Link to={oneoption.to}  className="option">
                {oneoption.icon}
                <h4>{oneoption.word}</h4>
              </Link>
            </div>
            )}
          </div>
        </div>

        <div className="telegramlink">
          <p className="linktext">دریافت سیگنال دقیق از کانال بزرگ بورس شارپ</p>
          <button className="buttonlink">Burssharpedu@</button>
        </div>

        <Shape />
        <Shape2 />

        <div className="make-it-center">
          <div className="line"></div>
        </div>
      </section>
    </div>
  );
};

export default Homefirst;
