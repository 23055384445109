

const Homeoursirvices = () => {

  const Homesirvicesproperty = [
    {
        sirvicetitle: "سرمایه گذاری در بورس",
        textsirvice: "روش های مختلف معامله گری در بورس به شما آموزش داده می شود",
      },
      {
        sirvicetitle: "مشاوره سرمایه گذاری",
        textsirvice: "اصول معامله گری در بازار های  مالی آموزش داده می شود",
      },
      {
        sirvicetitle: "آموزش معامله گری",
        textsirvice: "تحلیل روزانه بازار های مالی در  قالب پست های متفاوت",
      },
      {
        sirvicetitle: "الیوت شارپ",
        textsirvice: "ارائه مشاوره به سرمایه گذارانی که قصد سرمایه های  گذاری کلان دارند",
      },
  ]
  return (
    <div style={{ textAlign: "center" }}>
      <div className="make-it-center">
        <div className="h-oursirvices-title-part"></div>
        <h2 className="h-oursirvices-title">خدمات ارائه شده ما</h2>
        <div className="h-oursirvices-title-part"></div>
      </div>

        <div className="make-it-center">
        <div className="foursirvices">
          {Homesirvicesproperty.map((Hsproperty, key) => (
            <div className="onesirvice">
              <h2 className="sirvicetitle">{Hsproperty.sirvicetitle}</h2>
              <p className="textsirvice">{Hsproperty.textsirvice}</p>
            </div>
          ))}
        </div>
        </div>
    </div>
  );
};

export default Homeoursirvices;
