import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import "./App.css";
import Home from "./components/home/Home";
import Logo from "./components/home/Logo";
import Logo2 from "./components/home/Logo2";
import { useState } from "react";
import icon from "./images/blogging_1187595.png";
import { Footerdesktop } from "./components/home/Footer";
import Weblog from "./components/weblog/Weblog";
import News from "./components/market news/News";
import Investment from "./components/investment/Investment";
import Call from "./components/Call/Call";
import Menulittlesize from "./Menulittleinfo";


function App () {

  interface props{
    to:string,
    name:string,
    src: string,
  }

  const imgwidthheight : number = 28

  const [seeMore , setseeMore] = useState<boolean>(false);
  const [seemenu, setseemenu] = useState<boolean>(false);

  return (
    <div className="App">
      <Router>
        <nav className="navbar">
          <button className="login">ثبت نام</button>
          <button className="login2">ورود</button>
          <div className="links">
            <a className="link more" onClick={() => setseeMore(!seeMore)}>
              {seeMore? <img
                style={{ marginTop: "7px",transform: "rotateZ(180deg)",}}
                width="15"
                height="15"
                src="https://img.icons8.com/material-rounded/24/sort-down.png"
                alt="sort-down"
              ></img>:

              <img
              style={{ marginTop: "5px" }}
              width="15"
              height="15"
              src="https://img.icons8.com/material-rounded/24/sort-down.png"
              alt="sort-down"
            ></img>}
              بیشتر
            </a>
              {seeMore ? (
              <div className="morelinks" onClick={()=> {setseeMore(false)}}>
                <div className="morelinks-option">
                <Link to="/ourservices" className="link-more margin-auto" style={{margin:"auto"}}>
                  خدمات ما
                </Link>
                <Link to="/call" className="link-more margin-auto">
                  تماس
                </Link>
                <Link to="/investment" className="link-more margin-auto">
                  سرمایه گذاری و بورس
                </Link>
                <Link to="/weblog" className="link-more margin-auto">
                  وبلاگ
                </Link>
                <Link to="/" className="link-more margin-auto">
                  درباره ما
                </Link>
                <Link to="/" className="link-more margin-auto">
                  حساب کاربری من
                </Link>
                </div>
              </div>
            ) : null}

            <Link to="/Generalcontent" className="link">
              مطالب عمومی
            </Link>
            <Link to="/VIPcontents" className="link">
              مطالب
            </Link>
            <Link to="/ElliotSharp" className="link">
              الیوت شارپ
            </Link>
            <Link to="/marketnews" className="link">
              اخبار بازار
            </Link>
            <Link to="/" className="link chosen">
              خانه
            </Link>

            <div className="search">
              <button className="search-btn">جستجو</button>
              <input
                className="input-search"
                type="search"
                placeholder=" جستجو کنید"
              ></input>
              <img
                width="20"
                height="20"
                src="https://img.icons8.com/ios/50/000000/search--v1.png"
                alt="search--v1"
              ></img>
            </div>
          </div>
          <h1>
            <Logo />
          </h1>
        </nav>

        {seemenu ? (
          <div className="menu">
            <div className="options-in-menu-remove" onClick={() => setseemenu(false)}>
            <div className="options-in-menu">
              <div
                className="hide-button-in-menu"
                onClick={() => setseemenu(false)}
              >
                X
              </div>

              <div>
                <Link to="/" className="Account">
                  حساب کاربری من
                </Link>
              </div>


              {Menulittlesize.map((info, key)=>
              <div className="links-in-little-version">
                <Link to={info.to} className="link">{info.name}</Link>
                <img src={info.src} width={imgwidthheight} height={imgwidthheight}>
                </img>
              </div>
              )}


              <div className="aboutus">
                <div className="button-down-menu">
                  <img
                    width={23}
                    height={23}
                    src="https://img.icons8.com/ios-filled/50/000000/phone.png"
                    alt="phone"
                  />
                  <br></br>
                  <Link to="/call" className="threelinkmenu">
                    تماس
                  </Link>
                </div>

                <div className="button-down-menu">
                  <img width={23} height={23} src={icon}></img>
                  <br></br>
                  <Link to="/weblog" className="threelinkmenu">
                    وبلاگ
                  </Link>
                </div>

                <div className="button-down-menu">
                  <img
                    width={23}
                    height={23}
                    src="https://img.icons8.com/ios/50/000000/about.png"
                    alt="about"
                  ></img>
                  <br></br>
                  <Link to="/" className="threelinkmenu">
                    درباره ما
                  </Link>
                </div>
              </div>
            </div>
            </div>

          </div>

        ) : null}

        <div style={{position:"fixed",width:"100%",zIndex:"1"}}>
          <div className="logo2-part">
            <h1>
              <Logo2 />
            </h1>
          </div>

          <nav className="navbar2">
            <div style={{display:"flex",justifyContent:"space-around",marginLeft:"3%"}}>
              <button className="login">ورود</button>
              <button className="login2">ثبت نام</button>
            </div>
            <div className="links">
              <div className="search">
                <button className="search-btn">جستجو</button>
                <input
                  className="input-search"
                  type="search"
                  placeholder=" جستجو کنید "
                ></input>
                <img
                  width="20"
                  height="20"
                  src="https://img.icons8.com/ios/50/000000/search--v1.png"
                  alt="search--v1"
                ></img>
              </div>

              <div className="menu-icon" onClick={() => setseemenu(true)}>
                <img
                  width="25"
                  height="25"
                  src="https://img.icons8.com/ios-filled/50/000000/menu--v6.png"
                  alt="menu--v6"
                />
              </div>
            </div>
          </nav>
        </div>
        
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/marketnews" element={<News />}></Route>
          <Route path="/ElliotSharp"></Route>
          <Route path="/VIPcontents"></Route>
          <Route path="/Generalcontent"></Route>
          <Route path="/call" element={<Call />}></Route>
          <Route path="/investment" element={<Investment />}></Route>
          <Route path="/weblog" element={<Weblog />}></Route>
        </Routes>

        <Footerdesktop />
      </Router>
      
    </div>
  );
}

export default App;
