export const Shape2 = () => {
  return (
    <div className="shape2">
      <svg
        width="676"
        height="181"
        viewBox="0 0 676 181"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_719_475)">
          <path
            d="M397.74 61.1349L401.973 128.83C402.181 132.17 404.951 134.772 408.298 134.772H414.024C417.409 134.772 420.197 132.112 420.355 128.73L422.054 92.3593C422.387 85.2249 432.431 84.0058 434.462 90.8534L448.032 136.628C448.83 139.319 451.303 141.165 454.109 141.165H469.571C472.674 141.165 475.321 138.918 475.825 135.856L477.971 122.815C478.97 116.742 487.466 116.138 489.315 122.007V122.007C490.082 124.442 492.339 126.097 494.891 126.097H495.245C498.487 126.097 501.204 123.648 501.54 120.424L504.665 90.4472C505.374 83.6457 515.039 83.0217 516.617 89.6755V89.6755C518.207 96.3801 527.965 95.6766 528.577 88.8133L532.35 46.4826C532.981 39.4125 542.979 38.5642 544.791 45.427L564.849 121.377C565.584 124.159 568.1 126.097 570.977 126.097H574.248C577.387 126.097 580.053 123.8 580.516 120.696L587.526 73.7902C588.5 67.276 597.532 66.385 599.759 72.5833L624.389 141.115C625.293 143.631 627.68 145.309 630.354 145.309H635.048C638.286 145.309 641.004 142.868 641.35 139.649L643.32 121.335C643.964 115.351 651.802 113.549 654.994 118.65L665.085 134.772"
            stroke="#70FFE7"
            stroke-width="2.53521"
          />
          <path
            d="M11 50.6622L15.2323 118.357C15.4412 121.697 18.2112 124.3 21.558 124.3H27.2838C30.6692 124.3 33.457 121.639 33.6149 118.257L35.3136 81.8866C35.6469 74.7522 45.6912 73.5332 47.7214 80.3808L61.2922 126.155C62.09 128.846 64.5623 130.692 67.3688 130.692H82.831C85.9342 130.692 88.581 128.445 89.0849 125.383L91.2309 112.342C92.2301 106.27 100.726 105.665 102.575 111.535V111.535C103.342 113.969 105.599 115.624 108.151 115.624H108.505C111.746 115.624 114.464 113.175 114.8 109.952L117.925 79.9746C118.634 73.173 128.299 72.549 129.877 79.2029V79.2029C131.466 85.9075 141.225 85.2039 141.837 78.3406L145.61 36.0099C146.24 28.9399 156.239 28.0916 158.051 34.9543L178.109 110.904C178.843 113.686 181.36 115.624 184.237 115.624H187.508C190.646 115.624 193.312 113.327 193.776 110.223L200.786 63.3175C201.76 56.8033 210.791 55.9123 213.019 62.1107L237.649 130.642C238.553 133.158 240.939 134.836 243.613 134.836H248.308C251.546 134.836 254.263 132.396 254.61 129.176L256.58 110.862C257.224 104.879 265.062 103.076 268.254 108.177L278.344 124.3"
            stroke="#70FFE7"
            stroke-width="2.53521"
          />
          <path
            d="M278.128 125.018H292.895C294.378 125.018 295.663 123.99 295.987 122.543L304.216 85.8586C304.968 82.5062 309.77 82.5794 310.42 85.9531L325.703 165.337C326.35 168.698 331.128 168.789 331.902 165.455L340.718 127.471C341.051 126.035 342.331 125.018 343.805 125.018H363.44C365.097 125.018 366.474 123.741 366.6 122.089L369.582 82.7067C369.856 79.0925 375.001 78.6945 375.828 82.2237L385.272 122.571C385.607 124.005 386.885 125.018 388.357 125.018H389.028C390.747 125.018 392.152 123.648 392.196 121.931L393.65 65.2696C393.694 63.5516 395.1 62.1819 396.818 62.1819H397.505"
            stroke="#70FFE7"
            stroke-width="2.53521"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_719_475"
            x="0.861625"
            y="22.6271"
            width="674.171"
            height="157.956"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.53521" />
            <feGaussianBlur stdDeviation="4.43662" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.411377 0 0 0 0 0.934948 0 0 0 0 0.822754 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_719_475"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_719_475"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

const Shape = () => {
  return (
    <div>
      <div className="shape">
        <svg
          width="1064"
          height="285"
          viewBox="0 0 1064 285"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_306_153)">
            <path
              d="M626.19 96.4574L632.868 203.265C633.197 208.535 637.568 212.641 642.848 212.641H651.882C657.224 212.641 661.622 208.443 661.871 203.107L664.552 145.722C665.077 134.466 680.925 132.543 684.128 143.347L705.54 215.569C706.799 219.814 710.7 222.726 715.128 222.726H739.524C744.42 222.726 748.596 219.181 749.391 214.35L752.777 193.775C754.353 184.194 767.758 183.24 770.675 192.501V192.501C771.885 196.341 775.446 198.953 779.473 198.953H780.032C785.146 198.953 789.433 195.089 789.963 190.003L794.894 142.706C796.013 131.974 811.261 130.99 813.751 141.488V141.488C816.26 152.066 831.656 150.956 832.622 140.128L838.575 73.3392C839.569 62.1843 855.345 60.8459 858.204 71.6738L889.851 191.506C891.01 195.895 894.98 198.953 899.519 198.953H904.68C909.632 198.953 913.839 195.329 914.57 190.431L925.63 116.425C927.166 106.147 941.416 104.741 944.931 114.52L983.792 222.648C985.219 226.618 988.984 229.265 993.202 229.265H1000.61C1005.72 229.265 1010.01 225.415 1010.55 220.335L1013.66 191.439C1014.68 181.999 1027.04 179.155 1032.08 187.204L1048 212.641"
              stroke="#70FFE7"
              stroke-width="4"
            />
            <path
              d="M16 79.9338L22.6777 186.741C23.0072 192.011 27.3777 196.117 32.6582 196.117H41.6922C47.0336 196.117 51.4321 191.919 51.6813 186.584L54.3615 129.199C54.8873 117.942 70.7351 116.019 73.9381 126.823L95.35 199.045C96.6086 203.291 100.509 206.203 104.938 206.203H129.333C134.229 206.203 138.406 202.658 139.201 197.826L142.586 177.251C144.163 167.67 157.568 166.716 160.485 175.977V175.977C161.695 179.818 165.256 182.429 169.283 182.429H169.841C174.955 182.429 179.243 178.566 179.773 173.479L184.704 126.182C185.823 115.451 201.071 114.466 203.561 124.965V124.965C206.069 135.543 221.466 134.433 222.431 123.604L228.385 56.8156C229.379 45.6607 245.154 44.3222 248.014 55.1502L279.66 174.983C280.819 179.372 284.789 182.429 289.329 182.429H294.49C299.442 182.429 303.648 178.805 304.38 173.907L315.44 99.9009C316.976 89.623 331.226 88.2172 334.741 97.9968L373.601 206.124C375.028 210.094 378.793 212.742 383.012 212.742H390.419C395.528 212.742 399.815 208.891 400.362 203.811L403.471 174.915C404.486 165.475 416.853 162.631 421.89 170.68L437.81 196.117"
              stroke="#70FFE7"
              stroke-width="4"
            />
            <path
              d="M437.469 197.251H460.768C463.107 197.251 465.134 195.628 465.646 193.345L478.629 135.466C479.816 130.176 487.393 130.292 488.418 135.615L512.531 260.866C513.552 266.169 521.09 266.311 522.311 261.051L536.221 201.12C536.747 198.855 538.766 197.251 541.092 197.251H572.071C574.686 197.251 576.86 195.236 577.057 192.629L581.763 130.493C582.194 124.79 590.313 124.162 591.617 129.731L606.518 193.391C607.047 195.652 609.064 197.251 611.386 197.251H612.445C615.156 197.251 617.374 195.09 617.443 192.379L619.737 102.981C619.806 100.27 622.024 98.1091 624.735 98.1091H625.819"
              stroke="#70FFE7"
              stroke-width="4"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_306_153"
              x="0.00390625"
              y="35.7004"
              width="1063.69"
              height="249.22"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="7" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.411377 0 0 0 0 0.934948 0 0 0 0 0.822754 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_306_153"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_306_153"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Shape;
