import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Newsslider = () => {
  const settings = {
    className: "hello",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (

    <div className="two-events-part">
      <Slider {...settings}>
        <div className="Stock-conference-report">
          <div className="Stock-conference-report-info">
            <p style={{ fontSize: "25px" }}>گزارش همایش بورس</p>
            <p style={{ marginTop: "5px", fontSize: "18px" }}>
              همایش بین المللی بورس و بازار های مالی
            </p>
            <p style={{ marginTop: "5px", width:"80%",marginLeft:"20%",fontSize:"13px" }}>
              بیش از صد ها فعال حوزه بورس و بازار های مالی در این گرده همایی
              شرکت کردند
            </p>
            <button className="see-details-button">دیدن جزئیات</button>
          </div>
        </div>
        <div className="Stock-conference-report2">
          <div className="Stock-conference-report-info">
            <p style={{ fontSize: "25px" }}> شارپ</p>
            <p style={{ marginTop: "5px", fontSize: "18px" }}>مسیر مطمئن برای فعالیت در بورس</p>
            <p style={{ marginTop: "5px",width:"80%",marginLeft:"20%",fontSize:"13px"  }}>ما راه و چاه را به شما نشان می دهیم انتخاب با شماست که کدام مسیر را انتخاب برگزینید</p>
            <button className="see-details-button">دیدن جزئیات</button>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Newsslider;
