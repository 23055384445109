import image from "./images/17752.jpg";
import image2 from "./images/3 Ways to Get Back on Track for Business Growth and Financial Freedom _ Entrepreneur.jpg";

const Weblog = () => {

  const Webloginformation = [

    {
      weblogimage: image,
      weblogtitle:"ریزش 30 تا 90 میلیونی قیمت خودرو های مونتاژی در بازار",
      addressofweblog:"وبلاگ leave a comment",
      weblogp :"این محتوا قفل و محدود شده است لطفا عضو شوید",
    },
    {
      weblogimage: image2,
      weblogtitle:"قطعیت در بازار های مالی",
      addressofweblog:"وبلاگ1 comment",
      weblogp :" ....یه مشکلی که اغلب توی بازارهای مالی دارند اینکه دنبال قطعیت بالا هستند و بازار اونا را ناامید می کنه  ",
    }
  ];

  return (
    <div>
      <section className="weblog-page">
        {Webloginformation.map((webloginfo, key)=>
        <div className="weblog" key={key}>
          <img src={webloginfo.weblogimage} className="weblog-image"></img>
          <div className="make-it-center">
            <div className="weblog-title">{webloginfo.weblogtitle}</div>
          </div>
          
          <div className="address-of-weblog">{webloginfo.addressofweblog}</div>
          <div className="make-it-center">
            <p className="weblog-p">{webloginfo.weblogp}</p>
          </div>
          
          <button className="weblog-button">ادامه مطلب →</button>
        </div>
        )}
      </section>
    </div>
  );
};

export default Weblog;
