const Fourimages = () => {
  return (
    <div className="fourimagespart">
      <section className="fourimages">
        <div className="oneimages eachimage1">
          <h3>فارکس</h3>
        </div>

        <div className="oneimages eachimage2">
          <h3>رمز ارز</h3>
        </div>

        <div className="oneimages eachimage3">
          <h3>بورس ایران</h3>
        </div>

        <div className="oneimages eachimage4">
          <h3>سرمایه گذاری</h3>
        </div>
      </section>
    </div>
  );
};

export default Fourimages;
