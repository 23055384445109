const Menulittlesize  =[
    {
      to:"/",
      name:"خانه",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2klEQVR4nO2VXQrCMBCE89pO2UcVLyKagAdqjytWT6Ms/iRIjCbZ1IJZWAgJm/kylKlStTIK2C641S+qbfcrkD5y83pScWCz7MgcOjIXbpA+N81uPeHLzXgTNqO7Lu4EnJeDzIlfzd+A3SvoBDzi9qwwBALixSHwhXgxCESIi0MgQVwMAhni2RAQEE+GaJ2Q4ZYIFr7DJmYgrPASr9xKqNw73zoB0sODMgTgXubr0IyNbT14SUG6dy2TBrj/QfsPhqnnQMxZykwFqA5UB+bvQEzgpMzMF6DWX9UVc68b4GudiYwAAAAASUVORK5CYII=",
    },
    {
      to:"/",
      name:"اخبار بازار",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9klEQVR4nO2aPWgUQRTHJ5pIbt+8t0k0RBQUAiqoiBg/kn3v3MaIooWNlY1YCikUxM6ktFMQbERFUGwE8SO9nRgiIhrwE4xNwG9UJIUSmb2583K5u731Lnsb3T+85mZn9/1m5s3be7NKpUqgHMdf7rjS1zzLblFq/ZI6MfraAOWTJplppgHy+bowXNfvyN2Mf2qU8bgNUF4FIMQ3GwICyJ9VE6S1HEhBipXOSIP0zy0tjfxQu3IyzIBkCIgHlfJbEwlinq8jbdfyGtEbCAXRJKMNzxXEdyqBaOTHgHK6jAU5DlA+AvElTXJWo4xZn78jytqqIEB8t/EgcjtKjGTI25r3rb1jYHVRU4tGuWoH4HpVkLhUDQSQR3LOyrnSNsSda6zfXwxYskFILpo2RD5Srm8+rrq6tlNiYqQ8CF/OgWQPVwMxDImOEWgESFxKQVQ6I/OjdGmp/21pgSu7NPF0le30h7kmSt5pTh5xo4HUknfSPIJpQlRpjOg0RjgZL42I25aG1XiJ+rsSv/1qkqc15JGJhQAyHFrrJRmOHSQuJQ4E0xiRNEYgjZEq0lr80CK0Zom4tC7k2rxDoTNiils22L/Vtf2ivAktCaFMljqDKPvtK/6t0jbH9TZrkjOBo2VB5GuhQKeUWqRJfpkfenoG4W9BELP7KhShC+aQ7KlY3yW+pyLI+Jrzg6eLyV4GN4OBjSpmAezosSDvghpujUL01tl+z+euReQR1QTZrD9j4qzWPoByzK6MK4UfzYFJflQyGW+Filma5KiNoTGl+jPhPfx2IHlm+jjIe2c1AfINe7PxTKZ/5fy5Xdax1uDojYIRHnVd6ax8bV9b/mwESO7PWY6msyZ5Yi/4oElOua70zj9E4fm9f3Y+nkLMnpg9oAcXO+TtBuQHdvW8Lzn8KYHJnwQVjKdi/BJicu6WzW81yiNzoFP0d+AFdMim2pIbyjVzbheaG2I0IJ4AlOPd3b6OOuMt7Z28qplfDgHwhiAxan9ZVOcXpH4Dr8M0Bz7060QAAAAASUVORK5CYII=",
    },
    {
      to:"/Investment",
      name:"سرمایه گذاری و بورس",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPUlEQVR4nO2ZzWsTQRTA14qSZN6bTVLSIlYL3ip+IAVN8l4SBb0IIkUF/4Fqe/DS/8CrCB48CaLiN3gQbx70LF786kEpgoi52oNCtaYamd1pGmnzNbuz2WIfLBnm8/123nvzZuM4G7KOBCTXbT1C8vtUqrRt3YNAlDCgF7Q1b2QwYB9kdvlXiMJQ2OusWtDWvACcA0nvrO8MWAbxylHAQAQgkcBADyBB+1qFgQhBrMJAF8oBlMoC+VpTJLoi0rzPdF4hCkOhR7MOIANC0s0Wh91vgXTRcF4ndJi2b07yBd1eTWHxeC5XgWz2oAS3dEogz3ttLp82AQkdpt2CgPTWPwtoYvW44pTXhvS83bw9PrN2QCT99NvyybWdlusC+VuIIHU7IMifPWVFYY/xAiHoEYKP0CU/TNKcEOW9znoFyeUqAMiv/T60BEgPhFs84jjOpiBKRw6ixItSki+D5MWVtJzmBPKM41QSxgtHDbIsiUxphzo3AOlLAwjpk3DpsPHi/QBZkTObEekEIL3SJvcjlab9xgr0D6QhA0LydZ0zPTFWwDaIQPqu6l2XM63GSpnP6rNk3lgB6yCSX+i3fbbV2GS2MhJ/EOQZ7dAvHWd8S4dz5rGxArZBhoePCYH8UedTzwCIM5mjrqpPueUDgHwVJP8Byb8Q+VBsQZS4Lu8Skj+0yosE8leA0kknBAH7UWv3VpA8DUhPGxDID0HS+cHBIhov3M/wG3gxmwL/I0hYksjQTnD5HCDf1R8iqiB5QT9Vv47uqD6qb+xAlE+B5FteJt31ZYuW1HcDlYnHBkRdA8BXriaQHqnrsgrlyWR+u38LzSdV2Qvvsjjl96GaDjD34gOyfBWA1d8AWo4BmtA6LloD6dXWm8yl5r9tnk65PK5SncaOZCsjqk61/bMjnXQ0ATG19bXL3PW40EEC2brkut7JSZB0G5Df6Muav5OqrOpUm8uTzTsZPkgwW68DlMe6H1ceswkSyNYhLj4S1NYhNj4S0NYTMfKRQLYOGz7SRjZ8RMbUR6CLp2nMQh98ZCF8EKT7kZ8j2CH7NRGVa/n/LfZ8r7hhOq7jfSSIJNKFUS/7VRlua1v3s990YTTouL/h2p8F7fFsPQAAAABJRU5ErkJggg==",
    },
    {
      to:"/",
      name:"الیوت شارپ",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD1klEQVR4nO1ZX4hUZRT/TMuZOX/uurRJVvoQ9KAkyWLs3nNm5yEkH0QIkUCFEAnqRSQVUkQUpIjAeoiK6qGgh+opJB/UNXrRxBRF8Q9aYg8p+Jd1RXdd/8Q39+xuzszu3pm9s3eH5gcDw73nO/f8znf+3e8610QTTUx6AMvXwHoGSX90jQhg+SVH8haitrlGBLJuRtLPkPSIa0RgoG8DdD4DJDddI4IovxFILgDpB2nb0kSqQJadQPqnT8q4azItnXOQpBtIbiPpvswMme3SAlG4NBd0LQDW3+OuQZJuZH009CPd69JCW1sBgeUcsm6NuwZIev9LAEhvubRApKt9SADr2bhrkPTXSbMDQPIVkP6FLFvirsmSbI88L/eRdM+4cwBI1gPrQTcxmOJ3q+h5lDcS0Yis7wLLVTcByHH+dQubv50rTEtEaWvrq1wLASuH+4GkP245BNZdUezLJpcUaiVQbTnMeMIs95Glz89BLm0C5eVQekeTR9aPTfY7Nxlg9X+YAOs1n6QVhZ9tzwHpdS+X5c6FLm1YA7tmnr+LrP32f91I47ORjN2xq51LYieiB5BsiwyS3wZHCkvOuwBdL5cRID1mXXdVogRqmUsQC08DaY+XJ8qHw6T0SyN1yrmO7LB8mLfrV5wrZBIlUCERb49JgGWnhcOusjhna1Ksnw7Jk/5gunckanykXE6U7EDPaA3Gh5gPE2R9AC06v/R+LtB2y4eHvmlls+EsZL2HLAPZ1sLzCZtfmAaBn2OK3ulD0jv2f/tIK4D1GyP6/UgyyPJ+JCN9QN74YvjsTth4H5uyxhLrvHPtTyKqWKMZIJKOUnkifcnf8x5lDl8cRfUTQHqjZGf/SNj8xdOR9KJ5Z8XgVWD5yK6dmzlzETxGmPQn26HPx9IO/m2rnjM/kK415Se9x4bvzH0KSY/bQ78oie2HyHonTiwj6b66zfzes8hyOSqD4dKyh6PO9YZG93WJGbTHSH0Yv7/o3mIOUL470fdePwlamBwaqfUD6XuWiP3FBI9Cp4e5o9WliSAotAwmGAT6WpWJeN6lDSDZYZ7dX30ijj5tVtTBejBHsqFmgx9TFp1FFjtvpTKZdCIS6RI/gSZGAFk/qTgCjJ2IVQ16hil+0PMn0IkQyGY7nrPK8iAXhK+4OgNA5iHrP8hyCUkPJzEuD1jl+dlNcM7lxrMDZeMyy4Fq1mdm5F9AkqO+M48xPtQHMM5jPMR8Fwa6HFm/BQ7frJ+ldWzp/jsWsJxO5XtWZqiSSG9tx3jLpxbDMMgvc40I4HBl8YWE9Ahy+E7a9jTRxP8B/wIHyHVIGuX9ywAAAABJRU5ErkJggg==",
    },
    {
      to:"/",
      name:"مطالب",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACuUlEQVR4nO2ZPYgTQRTHB7TIZt6buRyCeuIHVhYiiOBd9r0YBBH8xMKzVkGwECz8iKCFHopia2ehtSBYqhgFCz9rRcTORqzUO0EP9SJvdxJvQ5I7koXs4vxhYJnP99v35mN3lPLy8vLyGqYQeS8gPdZI38FwI8tJI82IrYiVPQkIjXR52Mb1D8VT/zwRZ84C8NkgCMdUxhUE4RhYrkU2G24UMdytAPlJBGK5pnImiGEagFSXsJqJqIqVlSpnKharK1x4TatmrKmcCpr2e5CMCLxHMib47zyibbgdLE8CVJf16hCRx6VeoURrkvm0T/I7t4nL2hNiuL9oeYtSk0vSA0G+KuWIlTO9OtTIH8DwnLXVdcmB6FO3/ptlXRPSRwA+kA7ICG+KO+XX3eoU7bbN0cZk6Fk3Y3uCWD6W8IoJjwPyHTD8Bwz9kv4HBnGV33R62y1YpCsO5EQ/ILJDdypH5CPutHszLZDz8eGMTncEMfRe3pzW48vTBLGWS/EL4uepgFjL68UjGulVj9B72MvYfkAKpcpq5+mnqYBEBht+ITCFkfLaRD7ylFsMDqcNopGvOZDr6YEgnXTxeqoN8B0Y+lEq7bD9gkQfdnIkt1yT8AVDFwDpgSv7Ip5JDSQ+MtNvbehlC0KXN7rB7nYfqN/ll34C0n0J3dR3dkB+ND+8NNJFt3weHAREJnV7Uqq6dFE29QOCSEfnh5c29FYjf1NqIhgEZDFjpwoyOrrVyHyQ8AIob3AT8XavNpkEEWlD96Kl2NCtaMUx4U6VU5BDrs0cGP68UCxnFkSpakHmhdsEbyw8UGZBsiXwIBkTeI9k1SMaeVoe8vDzul1BMLHKnTS+KrlnyO1PbEPn3Ld9XclliXPPrMDk81qBdiU+jvKYNNKlBKVcloiLmnMmy0mLjUj1lie8vLy8vNSQ9BfHGHu7w+DzNgAAAABJRU5ErkJggg==",
    },
    {
      to:"/",
      name:"مطالب عمومی",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEw0lEQVR4nO1aS4gcVRR9MTGIHxB04QcRRFQQVBjjJy56YVL3U03PINTCVQR1Yve9bRSN0VW78xNBYnQn6jhxIwgmfjaSpRIXLl2YaAyjaIgog/gZTTLK7eqedNdUd1X/pqsyfeBB0/XqdZ26n3fufe3cBBNMMEEKeFzdiqyLNqAo97rzHcByAFn/swEk8+58wdTU7IXNz4VCbRNwZRpJDzbJrpBmOUSkMzYn7t5cAFmeBpYlYJkD1seR5GiU6KpBctTmhvfIkq3h8oAgqG0OHziBYMKwNXJjaWCZGwLhOZcXgLlxjMWQZB9z5e7t25+6xIZ9BtLX4j1Cqi4PKBRqm6IxC6Q/er7e3uke5sodNqf9Bek3rYkssyDSmahlu5FtIx2xNPhSclkHsByKZN99ae9Flv3t9+pBl1UQ6X3I+l40Fj1f72qdB6y+uS+w/ECk1HoNsXpPTOwfMHXmsgYM5eKqbFsoVC5tnWdEW64vtF4rlXZf1iFrL7qsAdcbYY+rW00bJ7m0uXGD9ILHFUx0aZL5TLp0E1G9bPus6z9pfdhxLum7tnV5XuXGxlcbAJ64GlmPraloASsSItuSbTlJ94V7sfyTdlsCllMtc5eB5Cyy/tvYGY67cQsP7kK6V+EBJI91laUh+YdHSjRRWtatJ/stTi2R2bAGgH0XtWw3aQkkj6TU48vAutPltXgAknkg/a5hvR7Wkp9GGtOjKg+R5ftYr/Flz7aSXOP7O69F1mdjvYVGHNMrDQDSd8w1LR6TCdocmxvfADBCQHomkhueif42kDy3am3YdbNb8xaPL6W4Fo99Z9eSWjz1NSLubNtQdF7D0m0JrFarXeDy1sQLgmBjv4SDINjoxoUwM/fepiWSm2LCYHd0nsVxJGmdZq5c5fIGy9Bxic1Im6XrKsuXPXFJC1i+dXmDlZ5A+ltPmT4MgQULo4EfwPPK14VvVI8gyx9A8juSfoGsT05P77rcjQhAImkJE+mjA/1Yyco3X3YAy+HuAqD+At4Y1XaAvswmChCslPtaPLDs6AuYWwDpnz0KiGVg+RSx6lk1M1TSJMcbe/GZGKFxsvcFsXwbsrxi0mxQ1dRIHF+bti0WZy8e4vZ2zFRWYw/f4BX1FktQfZ1Z4RBIdhiLjf709S5LwNERbs2gH4Gv29x6IIxtMadfWSIqFHZctD4Ic9Pq+jOQPF8szl65LgjjOXf/C0le7uf0kEjuBJI3rVEIpL9Y4p1Ks844CZ8b8lIakhYKpg+Q5MsOnrM3F4TNxbs9o++Xb7CXElqyyzosp3JBOE5AWG2LWGEk+ThtuwfyQhhIXmg+z/0zckW9MoqpmlJ4yt5MEzZXNle1ZINY3oIkbyPr372vJaeB9NUcJa0BBslnJo8TiTYx9gceTLP7rldg90UPA+sDVi5aMWAHZEjygbnQ2IjWz5z1ob4bdria5JIdYHVzE+sdNZoCJ9aOrNXe+qK9/L6INjGI3LO3bEUBkr4fW68OxaJy1gwwtAYdkB4B1gcH/XPYijhoP+0bME71E4DKrS7LCILaZvQ1qGdP1uU1TUjjhvW6LO6Q5de0CcnKx7E21IeBUPTXrf75SBNSFmH/BUHWt0Kr1y3/ei5PDCaYYIIJJnCD43/vPCQiDYHXigAAAABJRU5ErkJggg==",
    },
    {
      to:"/",
      name:"خدمات ما",
      src:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEr0lEQVR4nO1aS4gcVRR9mZmE7rqfGmlDjCKCiEoEDYzRmbq3qI1/wQRhFq4U/CMKgt+dOz8RJFF3oiYzbgTB8beRLBVduHRh/BKDkYgyiJ+McTJyu6ud/lR3V3d1T3dl+sCFYfq913Xe/bzzbrVzY4wxxhgpQBQGQLJsRhTMubMdSLqIrGsVkwV39mBm6/rf0RRiuBdYl9bJVgxY30PUfTYmeW4O4JE8hiynkPUQkD4CLEcbiTYTl6M21ubYXFvD5QO7tsVk17KZnMqNp7HspayE9ZDLC6ASmk0eQ5YDRHrtjh3Xg5n9jSQHkyICWB92+UA0lZCzx71puarVDM8PdtuYhpz+qr6QjSgQdV+jZ9uRrSdd72mi4DY36gA7ZuoJH0g7F0lfbgjrJTeqQFRB0rcac5E5vKZ2HFF4azl8SX70SG6u/0xmm3KfdNHUmRs1AMlyUrXdvj3C2nFGdJ2MHKv9rFQKKPGMJll2owbYbISJwsC0caeQtjAukyY55rHe1DGkWRZGMqSraNLLJAddz0VL3m05luWwHV3Ms5fE/9rieeFOIP16Q0ULYri36Vjyg92d5sVn8UraYwlYTtaMPYOsq8j6T5wC37mhCw+/NeluhQdy8EAHWbqKKHe74UpLXbGQtTy1QmZmDYA4jFfSSkvE8J6UWvwMstyf28sDsiwA6bdx6Haxjvw04JwezPUQSL5PjBpfnywWg/OLxdkLkOWpxGihAed0TQPgTQtNy8dOBG2MjW3VAIgJ/Vs3D/WJxu9Glqcb1yYKLnMb3eKxituixbNUqcadWjzRVGM42zHUOKqyMfUFzDk34fLXxJuf7J3w/KQbFqwy99KmJdJLm0M1fLxxXJzHtbXgtOdF57m8Ia7QTYXNSJunzayAJRct/cblDXb1BNLfuqz2q6bZkXQx8wMUzgkvtB0Fls+A5A8g/R1YPwXSR30/mnYDArA8lJqwr/dm+rJSKSCP5E4kOdJOAFQ2QF4d1HGAvt6XQoA82OPy85MehzeWqyvpn12GlIn6jzwObrDbTD9Jm5iI87j+bK7Yz90vOK1XIuuLsTTLqJrKwuLLsrbdOeP1g7A5wK6CprLiM3wL4tzlVqB6emeFfSDZItyXrbFXmJ67yI0ScECEaysosLwPvl7nNgnhtf+N5AsrRM5Fhc1BmKsmJ4DkGcTo3E1CWKv2F7C80MvbwyIHVwPra9YoBJZfrPC6NOsMmXDZgOX5dDSjgukDIPm8xVr7c0HYQrzdM/q+XmybEnuy3cadzAnhRAEx4VFwC7B8kLbdA3khDKTPVp+HaE/JbkbJt6aOtn/ECcuJSv7ObC3y3B5geQNZ/u5hndPI+lJuilYmI/3Y5HFHolUM/YEzaHZ7Jeu6BbbdPTmCKLfbddEuA/aCDEjfiUNoWGSPE4V39dyww+QfpRxuFybWOyq3WUh/2DCPVpoPz5U3Pwswm9ybsEsBkr7d4r7aD1s1B/StQQfWumG9I+uPw2rEQe3bvqz2IYBc4UYbu7ahr/NWPeNOyMYVpGHDel2Wd0D6a9qCVLk+DrGh3h9EBfM6sHwy0II0irDfggDL6+b1sudJX8nlG4MxxhhjjDFcdvwHB4fhvV7+aUsAAAAASUVORK5CYII=",
    },
  ]

export default Menulittlesize;