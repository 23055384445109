import Homefirst from "./Homefirst";
import Homeexplain from "./Homeexplains";
import Homeoursirvices from "./Homeoursirvices";
import Whyworkus from "./Whyworkus";
import Newsinhome from "./Newsinhome";
import Fourimages from "./Fourimages";
import Freesubscription from "./Freesubscription";

const Home = () => {
  return (
    <div>
      <Homefirst />
      <Homeexplain />
      <Freesubscription />
      <Homeoursirvices />
      <Whyworkus />
      <Newsinhome />
      <Fourimages />
    </div>
  );
};

export default Home;
