const Investment = () => {

    const Howwework = [
        {
            title:" بازار فرابورس",
            text:"سرمایه گذاری در فرابورس ایران",
        },
        {
            title:"بازار بورس ایران",
            text:"در بازار بورس ایران"
        },
        {
            title:"بورس فارکس",
            text:"سرمایه گذاری در بازار های مالی خارجی نیازمند تخصص و دانش",
        },
        {
            title:" ارز دیجیتال",
            text:"بررسی انواع ارز های مرسوم",
        },
    ];

    const Prices = [
        {
            title:"اشتراک ویژه برای دسترسی به اطللاعات کانال",
            price:"۹.۹۹۹.۰۰۰ تومان",
        },
        {
            title:"خدمات آموزشی در الیوت شارپ",
            price:"۵.۹۹۹.۰۰۰ تومان",
        },
        {
            title:"آموزش تریدری با متدهای نوین بازار دنیا",
            price:"۴.۴۹۹.۹۹۹ تومان",
        },
    ];

    const Ourservice = [
        {service:"خانه",},
        {service:"اخبار بازار",},
        {service:"الیوت شارپ",},
        {service:"مطالب VIP",},
        {service:"مطالب عمومی",},
        {service:"خدمات ما ●",},
        {service:"حساب کاربری من",},
        {service:"وبلاگ",},
        {service:"درباره ما",},
        {service:" سرمایه گذاری و بورس ●",},
        {service:"تماس",}, 
    ]

  return (
    <div>
        <div className="investment">
            <div className="investment-services">
                <div className='title-of-investment-page'>
                    <p className="title make-it-center">خدمات سرمایه گذاری که ما ارائه می دهیم</p>
                    <p className="make-it-center" style={{fontWeight:"300", color:"#0c0e3c"}}>خدماتی که بورس شارپ در زمینه بازارهای مالی ارائه می دهد در زمینه های زیر است</p>
                </div>
                <div>
                    <div className='make-it-center' style={{marginTop:"80px"}}>
                        <div className="line-titleofpart2"></div>
                            <p className="titleofpart2">چگونه کار می کنیم</p>
                        <div className="line-titleofpart2"></div>
                    </div>

                    <div className='four-reason-in-invetment-page'>
                        {Howwework.map((workreason, key)=>
                            <div style={{textAlign:"right",margin:"25px 0px 30px 0px",display:"flex"}}>
                                <div>
                                    <p className="title-reason-investment">{workreason.title}</p>
                                    <p className="text-reason-investment">{workreason.text}</p>
                                </div>
                                <div>
                                    <p className="dot" style={{margin:"0px 0px 0px 10px"}}>■</p>
                                </div>
                                
                            </div>
                        )}
                    </div>
                </div>
            <div className="make-it-center">
                <div className='prices-in-invetment-page'>
                    <div style={{display:"flex", justifyContent:"right",fontSize:"20px",marginBottom:"25px"}}><p>: قیمت</p></div>
                    {Prices.map((price, key) => 
                    <div style={{display:"flex"}}>
                        <p className="price">{price.price}</p>
                        <p className="price-title">{price.title}<span style={{margin:"5px 5px 5px 10px"}}>■</span></p>
                    </div>
                    )}
                </div>  
            </div>          
        </div>
        <div className='ourservices-investment'>
            <p className="ourservices-investment-title">خدمات ما</p>
            {Ourservice.map((service, key) =>
            <p className="ourservices-investment-service">{service.service}</p>
            )}
        </div>
        </div>
    </div>
  )
}

export default Investment;