import image from "./homeimages/images3.jpeg";
import image2 from "./homeimages/images4.jpeg";
import image3 from "./homeimages/images.jpeg";
import image4 from "./homeimages/images2.jpg";

const Homeexplains = () => {

  const Properties = [
    {
      title: "سرمایه گذاری",
      text: "آموزش اصول سرمایه گذاری در سایر بازار های سوده",
      imageexplain: <img className="h-e-image" src={image2}></img>,
    },
  
    {
      title: "تحلیل احساسی",
      text: "احساسات و واکنش معامله گران در بازار سرمایه را باید در نظر گرفت",
      imageexplain: <img className="h-e-image" src={image4}></img>,
    },
  
    {
      title: "تحلیل بنیادی",
      text: " یا همان فاندامنتال یکی از روش های اصلی تحلیل بازار است و بر اساس ارزش ذاتی سهام وداده های بنیادی شرکت است ",
      imageexplain: <img className="h-e-image" src={image3}></img>,
    },
  
    {
      title: "تحلیل تکنیکال",
      text: "با تمرکز بر داده ها و نمودار روندهای بازار را شناسایی می کند",
      imageexplain: <img  className="h-e-image" src={image}></img>,
    },
  ];

  return (
    <div>
        <section className='home-explain-page'>

          {Properties.map((property, key:number) => 
            <div className='h-e-part' key={key}>
              <div className="h-e-image">{property.imageexplain}</div>
              <div style={{display:"flex",justifyContent:"center"}}>
                <p className='h-e-title'>{property.title}</p>
              </div>
              <p className='h-e-text'>{property.text}</p>
            </div>
          )}

        </section>

    </div>
  )
}

export default Homeexplains