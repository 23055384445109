const Whyworkus = () => {

  const threereason = [

    {
      reason:"اعضای تیم افراد مجرب و متخصص هستند",
    },
    {
      reason:"تحلیل روزانه و رصد وضعیت سهام بصورت مستمر",
    },
    {
      reason:"وجود چشم انداز بلند مدت در تیم بورس شارپ",
    },
  ]

  const tworeason = [

    {
      reason :"دارای تجربه و سابقه فعالیت در بازارهای مالی متفاوت",
    },
    {
      reason :"جذب موفق سرمایه های کلان و سود دهی عالی",
    },
  ]

  return (
    <div>

      <div className="reasons">
        <div className="make-it-center">
          <div className="whyyouwork-part"></div>
          <p className="whyyouwork">چرا با ما کار میکنید</p>
        </div>

        <div className="make-it-center">
          <div className="reasons-image"></div>
        </div>

        <div className="make-it-center">
          <p style={{fontSize:"25px",margin:"40px 0px 25px 0px"}}>پنج دلیل اصلی برای انتخاب ما</p>
        </div>
        
        
        <div className="make-it-center">
          <div className="three-reason">
            {threereason.map((reason, key)=>
            <div className="make-it-center" style={{width:"100%"}}>
              <div className="reason">{reason.reason}</div>
            </div>
            )}
          </div>
        </div>

        <div className="make-it-center">
          <div className="two-reason">
            {tworeason.map((reason, key)=>
            <div className="make-it-center" style={{width:"100%"}}>
              <div className="reason">{reason.reason}</div>
            </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default Whyworkus;
