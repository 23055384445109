const Freesubscription = () => {
  return (

    <div className="free-subscription-member">
      <div className="f-s-part">
        <div className="f-s-infotmation">
          <p className="f-s-text1">می توانید عضو اشتراک رایگان ما بشوید</p>
          <p className="f-s-text2">بدون دانش در بازار معامله نکنید</p>
          <button className="f-s-button ">
            از همین الان معامله گری را شروع کن
          </button>
        </div>
      </div>
    </div>
    
  );
};

export default Freesubscription;
